export function extractParamsFromMultiSelectObject(manyEntity) {
  return {
    all: manyEntity?.all ?? false,
    include: manyEntity?.include?.map(entity => entity.id || entity.value) ?? [],
    exclude: manyEntity?.exclude?.map(entity => entity.id || entity.value) ?? [],
  };
}

export function checkEmptyParams(many) {
  if (!many) return true;
  return many.include.length === 0 && many.exclude.length === 0 && many.all === false;
}
