import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { canAbility } from './middleware';

export default {
  path: '/contracts',
  name: routes.CONTRACTS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.CONTRACTS },
  children: [
    {
      path: '/contracts',
      name: routes.CONTRACTS,
      component: () => import('@/views/Contracts/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS)],
      },
      props: route => ({ status: entityStatuses.ACTIVE, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/contracts/archive',
      name: routes.CONTRACTS_ARCHIVE,
      component: () => import('@/views/Contracts/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS)],
      },
      props: route => ({ status: entityStatuses.ARCHIVED, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/contracts/:id/edit',
      name: routes.CONTRACTS_DETAILED,
      component: () => import('@/views/Contracts/Edit.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.CONTRACTS)],
      },
      props: route => ({
        id: Number(route.params.id),
        from:
          route.query.pageName && route.query.id ? { name: route.query.pageName, params: { id: route.query.id } } : {},
      }),
    },
  ],
};
