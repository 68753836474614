export const CARDS = 'cards';
export const INVOICES = 'invoices';
export const CHAT = 'chat';
export const PROFILE = 'profile';
export const PROJECTS = 'projects';
export const ASSETS = 'assets';
export const CLIENTS = 'clients';
export const CONTRACTORS = 'contractors';
export const UNITS = 'units';
export const INVOICES_ISSUE_INVOICE = 'invoices.issueInvoice';
export const INVOICES_SHOW_UNPAID = 'invoices.showUnpaid';
export const INVOICES_EXPORT = 'invoices.export';
export const INVOICES_IMPORT = 'invoices.import';
export const BUILDINGS = 'buildings';
export const CLIENT_ROOM = 'clientRoom';
export const EMPLOYEE_ROOMS = 'employeeRooms';
export const NEW_INVOICES_COUNT = 'newInvoicesCount';
export const ANNOUNCEMENTS = 'announcements';
export const EMPLOYEES = 'employees';
export const DASHBOARD = 'dashboard';
export const CONTRACTS = 'contracts';
export const ISSUES = 'issues';
export const SERVICES = 'services';
