import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import * as typeClient from '@/constants/typesClients';
import { canAbility } from './middleware';

export default {
  path: '/clients',
  name: routes.CLIENTS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.CLIENTS },
  children: [
    {
      path: '/clients',
      name: routes.CLIENTS,
      component: () => import('@/views/Clients/index.vue'),
      props: { typeClient: typeClient.OWNER },
    },
    {
      path: 'tenants',
      name: routes.CLIENTS_TENANTS,
      component: () => import('@/views/Clients/index.vue'),
      props: { typeClient: typeClient.TENANTS },
    },
    {
      path: 'short-tenants',
      name: routes.CLIENTS_SHORT_TENANTS,
      component: () => import('@/views/Clients/index.vue'),
      props: { typeClient: typeClient.SHORT_TENANTS },
    },

    {
      path: '/clients/:id/edit',
      name: routes.CLIENTS_EDIT,
      component: () => import('@/views/Clients/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.CLIENTS)],
      },
      props: route => ({ isEdit: true, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/clients/create',
      name: routes.CLIENTS_CREATE,
      component: () => import('@/views/Clients/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.CLIENTS)],
      },
      props: route => ({ isEdit: false, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/clients/:id',
      name: routes.CLIENTS_DETAILED,
      component: () => import('@/views/Clients/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CLIENTS)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage }),
    },
  ],
};
